// @flow

import _ from "lodash";
import React, { Component } from "react";
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import { pubsub } from "@utils/";
import LazyLoad from "react-lazyload";
import { forceCheck } from "react-lazyload";
import { CSSTransition } from "react-transition-group";

import { initMapApi } from "@modules/googleMapsApi";
// import defineHtmlMapMarker from "@utils/htmlMapMarker";
// import MarkerClusterer from "@utils/markerclusterer";
import MapStyles from "../constants/MapStyles";
import Modal from "./Modal";
import PrecinctMapFilters from "./PrecinctMapFilters";
import PrecinctMapDetailCard from "./PrecinctMapDetailCard";
import CreateSVGOverlayFactory from "./SVGOverlay";
import queryString from "query-string";

import Loader from "../components/atoms/Loader";

let HtmlMapMarker;
let MarkerClusterer;
const mapPinSvgMarkup = `<svg class="map-pin" width="25px" height="35px" viewBox="0 0 25 35" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g class="map-pin__inner" fill="#F6525B" stroke="#FFFFFF" stroke-width="2">
            <path  d="M1,12.8893779 C1,6.87417378 6.16694233,2 12.5,2 C18.8330577,2 24,6.87417378 24,12.8893779 C24,16.4256037 22.7160686,18.6307072 19.6881219,21.4828379 C19.4597044,21.6979925 18.0373728,23.0057741 17.6250763,23.3995002 C17.5403708,23.4803904 17.4581104,23.5596149 17.3789405,23.6365726 C16.581588,24.414043 16.4234079,24.5692357 16.0905476,24.9048125 C15.2825883,25.7214328 14.4787921,27.4252673 13.5861168,30.0025167 L12.5478864,33 L11.5118,30.0018032 C10.7021193,27.6587725 9.76384756,25.7705817 8.91352217,24.9132852 C8.57906219,24.5744333 8.36681458,24.3663311 7.62022655,23.6405178 C7.52691273,23.5498015 7.43103307,23.4575982 7.331687,23.3629886 C6.93471182,22.9849397 5.56168473,21.7230628 5.33649602,21.5113016 C2.28864003,18.6451839 1,16.440011 1,12.8893779 Z"></path>
        </g>
    </g>
</svg>`;

type PropsType = {
    match: any,
    listApi: string,
    tenantApi: string,
};

type StateType = {
    mobileMapDrag: string,
    showAmenities: boolean,
    tenantTypes: any,
    activeTenantType: string,
    activeTenantGuid: string,
    tenantStringSearch: string,
    filteredTenants: any,
    showTypeFilters: boolean,
    activeTypeSubFilter: ?any,
    appliedFilters: any,
    activeMarket: number,
    showMarketListItem: boolean,
    userLocationDisabled: boolean,
    userLocationInsideBounds: boolean,
    userLocationShowError: boolean,
    logoLockup: ?string,
    loading: boolean,
};

class PrecinctMap extends Component<PropsType, StateType> {
    googleMap: any;
    mapLoaded: boolean;
    tenantMarkerClusterer: typeof MarkerClusterer;
    bounds: any;
    dragBarTouchDown: boolean;
    dragBarStartY: number;
    dragBarEndY: number;
    dragBarOffsetY: number;
    dragBarMoveY: number;
    marketPolygon: any;
    marketPolygonFriday: any;

    marketPolygonWeekend: any;
    marketMarker: any;
    userMarker: any;
    searchChangeTimeout: any;
    apiHostname =
        document.location.hostname === "localhost"
            ? `http://localhost:8080/https://uat.${
                  window.theme === "DarlingHarbour"
                      ? "darlingharbour"
                      : window.theme === "Barangaroo"
                      ? "barangaroo"
                      : "therocks"
              }.com`
            : "";

    markerListingAPI =
        this.apiHostname + (this.props.listApi || "/api/v1/precinctmap/getall");

    tenantDetailAPI =
        this.apiHostname + (this.props.tenantApi || "/api/v1/tenantinfo");

    //Override local dev env
    // markerListingAPI =
    //     "http://localhost:8080/https://uat.darlingharbour.com/api/v1/precinctmap/getall";
    // tenantDetailAPI =
    //     "http://localhost:8080/https://uat.darlingharbour.com//api/v1/tenantinfo";

    useLocalListing = false;
    showBounds = false;

    markerApiJson = {};
    mapElementRef = React.createRef<HTMLDivElement>();
    contentElementRef = React.createRef<HTMLDivElement>();
    resultsBodyElementRef = React.createRef<HTMLDivElement>();
    tenantListElementRef = React.createRef<HTMLDivElement>();
    amenityMarkers = [];
    tenantMarkers = [];
    DEFAULT_LAT = "-33.8585002";
    DEFAULT_LON = "151.2063187";
    DEFAULT_ZOOM = 17;

    MAX_FITBOUNDS_ZOOM = 20;
    MIN_FITBOUNDS_ZOOM = 16;
    SW_BOUND = [-33.862486925406145, 151.20466022752225];
    NE_BOUND = [-33.85357747897272, 151.2163782119751];

    restrictionBounds: any;
    dragBarTouchTolerance = 44;
    allowedFilters = ["features", "cuisines", "occasion", "precinct"];

    state = {
        mobileMapDrag: "collapsed",
        showAmenities: true,
        tenantTypes: [],
        activeTenantType: this.props.match.params.type || "all",
        activeTenantGuid: this.props.match.params.activeTenantGuid || "",
        tenantStringSearch: "",
        filteredTenants: [],
        showTypeFilters: false,
        activeTypeSubFilter: false,
        appliedFilters: {},
        activeMarket: -2, //1: friday, 2: saturday/sunday, negative value hides market polygon
        showMarketListItem: true,
        userLocationDisabled: true,
        userLocationInsideBounds: false,
        userLocationShowError: false,
        logoLockup: "",
        loading: true,
    };

    //
    // Lifecycle Methods
    //

    static getDerivedStateFromProps(nextProps: any, prevState: any): any {
        return {
            ...prevState,
            activeTenantType: nextProps.match.params.type || "all",
            activeTenantGuid: nextProps.match.params.tenantGuid || "",
        };
    }

    componentDidMount() {
        // get main logo lockup link/svg markup from main nav
        const logoLockupElement = document.querySelector(
            ".primary-nav .logo-lockup"
        );
        // this.logoLockup = logoLockupElement ? logoLockupElement.outerHTML : "";
        this.setState({
            logoLockup: logoLockupElement ? logoLockupElement.outerHTML : "",
        });

        // disable body scrolling
        pubsub.publish("disableBodyScroll");

        // fetch marker listing api and init google map
        initMapApi.then(() => {
            import("@utils/htmlMapMarker").then((module: any) => {
                HtmlMapMarker = module.default(HtmlMapMarker);
                import("@utils/markerclusterer").then((module: any) => {
                    MarkerClusterer = module.default;

                    this.setThemeConfig();
                    this.initMap();
                    this.attachEvents();
                });
            });
        });
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.activeTenantType !== this.state.activeTenantType) {
            // re filter tenants on type change
            this.setState(
                {
                    appliedFilters: {},
                },
                this.filterTenants(true)
            );
        }

        if (prevState.showAmenities !== this.state.showAmenities) {
            // show amenities toggled
            if (this.state.showAmenities) {
                this.plotAmenityMarkers();
            } else {
                this.clearMarkers(this.amenityMarkers);
            }
        }

        if (prevState.activeTenantGuid !== this.state.activeTenantGuid) {
            this.setActiveTenantMarker();
        }

        if (prevState.activeMarket !== this.state.activeMarket) {
            this.toggleMarketPolygon();
        }
    }

    componentWillUnmount() {
        // enable body scrolling
        pubsub.publish("enableBodyScroll");
    }

    attachEvents() {
        // binding to close amenity markers
        document.addEventListener("click", (e: any): any => {
            if (e.target) {
                if (!e.target.classList.contains("amenity-marker")) {
                    this.setActiveAmenityMarker();
                }
            }
        });
    }

    setThemeConfig() {
        if (window.theme === "TheRocks") {
            this.MAX_FITBOUNDS_ZOOM = 20;
            this.MIN_FITBOUNDS_ZOOM = 16;
            this.SW_BOUND = [-33.862486925406145, 151.20466022752225];
            this.NE_BOUND = [-33.85357747897272, 151.2163782119751];
            if (this.useLocalListing) {
                this.markerListingAPI =
                    "http://localhost:3000/assets/jsons/precinctMapTheRocks.json";
            }
        } else if (window.theme === "DarlingHarbour") {
            this.MAX_FITBOUNDS_ZOOM = 20;
            this.MIN_FITBOUNDS_ZOOM = 14;
            this.SW_BOUND = [-33.9, 151.17];
            this.NE_BOUND = [-33.83, 151.22];
            if (this.useLocalListing) {
                this.markerListingAPI =
                    "http://localhost:3000/assets/jsons/precinctMapDarlingHarbour.json";
            }
        } else if (window.theme === "Barangaroo") {
            // this.MAX_FITBOUNDS_ZOOM = 20;
            // this.MIN_FITBOUNDS_ZOOM = 14;
            this.SW_BOUND = [-33.869363, 151.19572];
            this.NE_BOUND = [-33.853537, 151.20535];
            if (this.useLocalListing) {
                this.markerListingAPI =
                    //"http://localhost:3000/assets/jsons/precinctMapBarangaroo.json";
                    "http://localhost:3000/assets/jsons/precinctMapTheRocks.json";
            }
        }
    }

    //
    // Handlers
    //
    handleMapClose() {
        window.location.hash = "";
    }

    handleAmenitiesToggle() {
        this.setState({
            showAmenities: !this.state.showAmenities,
        });
    }

    handleDragBarClick() {
        this.setState(
            {
                mobileMapDrag: !this.state.mobileMapDrag
                    ? "expanded"
                    : this.state.mobileMapDrag === "expanded"
                    ? "collapsed"
                    : "",
            },
            () => {
                if (this.state.mobileMapDrag === "expanded") {
                    this.googleMap.setOptions({
                        zoomControl: false,
                    });
                } else {
                    this.googleMap.setOptions({
                        zoomControl: true,
                    });
                }
            }
        );
        setTimeout(() => {
            forceCheck();
        }, 500);
    }

    handleDragBarTouchStart(event: any): any {
        if (this.contentElementRef.current) {
            this.dragBarOffsetY = this.contentElementRef.current.getBoundingClientRect().top;
            this.dragBarTouchDown = true;
            this.dragBarStartY = event.touches[0].clientY;
        }
    }

    handleDragBarTouchMove(event: any): any {
        this.dragBarMoveY = event.changedTouches[0].clientY;
        if (this.contentElementRef.current && this.dragBarTouchDown) {
            let dragBarShiftY = this.dragBarMoveY - this.dragBarStartY;
            dragBarShiftY = dragBarShiftY + this.dragBarOffsetY;

            this.contentElementRef.current.style.transform = `translate3d(0, ${dragBarShiftY}px, 0)`;
            this.contentElementRef.current.style.transition = "none";
            //this.contentElementRef.current.style.overflow = "hidden";
        }
    }

    handleDragBarTouchEnd(event: any): any {
        this.dragBarTouchDown = false;
        this.dragBarEndY = event.changedTouches[0].clientY;

        // reset move style
        if (this.contentElementRef.current) {
            this.contentElementRef.current.style.transform = "";
            this.contentElementRef.current.style.transition = "";
            this.contentElementRef.current.style.overflow = "";
        }

        // set drag state based off swipe direction and current state
        let mobileDragState = null;
        if (
            this.dragBarEndY >
            this.dragBarStartY + this.dragBarTouchTolerance
        ) {
            // swiped down
            if (this.state.mobileMapDrag === "expanded") {
                mobileDragState = "";
            } else if (this.state.mobileMapDrag === "") {
                mobileDragState = "collapsed";
            }
        } else if (
            this.dragBarEndY <
            this.dragBarStartY - this.dragBarTouchTolerance
        ) {
            // swiped up
            if (this.state.mobileMapDrag === "") {
                mobileDragState = "expanded";
            } else if (this.state.mobileMapDrag === "collapsed") {
                mobileDragState = "";
            }
        }

        // set new dragBar content state
        if (mobileDragState !== null) {
            this.setState(
                {
                    mobileMapDrag: mobileDragState,
                },
                () => {
                    if (this.state.mobileMapDrag === "expanded") {
                        this.googleMap.setOptions({
                            zoomControl: false,
                        });
                    } else {
                        this.googleMap.setOptions({
                            zoomControl: true,
                        });
                    }
                }
            );
        }
        setTimeout(() => {
            forceCheck();
        }, 500);
    }

    setDragBar(state: string) {
        this.setState({
            mobileMapDrag: state,
        });
    }

    handleSearchChange(e: any) {
        const searchValue = e.target.value || "";

        if (this.searchChangeTimeout) {
            clearInterval(this.searchChangeTimeout);
        }

        this.searchChangeTimeout = setTimeout(() => {
            this.setState(
                {
                    tenantStringSearch: searchValue,
                },
                this.filterTenants
            );
        }, 200);
    }

    handleFilterChange(
        filterId: string,
        filterOption: string,
        type: string
    ): any {
        let currentFilterType = this.state.appliedFilters[filterId] || [];

        if (currentFilterType.includes(filterOption)) {
            // remove filter
            currentFilterType = currentFilterType.filter(
                (e: any): any => e !== filterOption
            );
        } else {
            // add filter
            currentFilterType.push(filterOption);
        }

        const updatedAppliedFilters = {
            ...this.state.appliedFilters,
            [filterId]: currentFilterType,
        };
        if (currentFilterType.length === 0) {
            delete updatedAppliedFilters[filterId];
        }
        this.setState(
            {
                appliedFilters: updatedAppliedFilters,
            },
            this.filterTenants
        );
    }

    handleFiltersOpenClick() {
        this.setState({
            showTypeFilters: true,
        });
    }

    handleFiltersCloseClick() {
        this.setState(
            {
                showTypeFilters: false,
            },
            this.filterTenants
        );
    }

    handleSubFilterClick(subFilter: any) {
        this.setState({
            activeTypeSubFilter: subFilter,
        });
    }

    handleSubFiltersCloseClick() {
        this.setState({
            activeTypeSubFilter: false,
        });
    }

    handleFilterResetClick() {
        this.setState(
            {
                appliedFilters: {},
            },
            this.filterTenants
        );
    }

    //
    // Map Methods
    //

    async initMap(): any {
        if (this.mapElementRef.current) {
            // fetch marker listing api

            const markerApiResponse = await fetch(`${this.markerListingAPI}`);
            this.markerApiJson = await markerApiResponse.json();

            if (
                this.markerApiJson &&
                this.markerApiJson.tenants &&
                this.markerApiJson.tenants.length
            ) {
                // render map
                this.googleMap = new window.google.maps.Map(
                    this.mapElementRef.current,
                    this.getMapOptions()
                );

                // plot bound box
                this.createBoundBox();

                // plot market polygon
                this.createMarketPolygon();

                // plot amenities
                this.plotAmenityMarkers();

                // create market marker
                if (window.theme === "TheRocks") {
                    this.createMarketMarker();
                }

                // set tenant types in state
                this.setTenantTypes(this.markerApiJson.tenants);

                // ilitial setting of filters
                this.setInitialFilters();

                // initial filtering of tenants (tenant markers replotted after filtering)
                this.filterTenants();

                // first map load trigger (make sure map tile have loaded)
                window.google.maps.event.addListenerOnce(
                    this.googleMap,
                    "tilesloaded",
                    () => {
                        // set active tenant marker/markets marker on load (uses hashrouter hash)
                        this.setActiveTenantMarker(true);

                        this.onMapLoad();
                    }
                );
            }
        }
    }

    onMapLoad() {
        // set mobile expand state
        this.setState(
            {
                mobileMapDrag: "",
                loading: false,
            },
            () => {
                setTimeout(() => {
                    forceCheck();
                }, 500);
            }
        );
    }

    getMapOptions(optionsObj: any): any {
        this.restrictionBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(...this.SW_BOUND),
            new window.google.maps.LatLng(...this.NE_BOUND)
        );
        return (
            optionsObj || {
                // zoom: this.DEFAULT_ZOOM,
                // center: this.fromCoordinatesToPoint(
                //     this.DEFAULT_LAT,
                //     this.DEFAULT_LON
                // ),
                maxZoom: this.MAX_FITBOUNDS_ZOOM,
                minZoom: this.MIN_FITBOUNDS_ZOOM,
                restriction: {
                    latLngBounds: this.restrictionBounds,
                },
                scrollwheel: true,
                streetViewControl: false,
                draggable: true,
                disableDoubleClickZoom: false,
                clickableIcons: false,
                zoomControl: true,
                fullscreenControl: false,
                mapTypeControl: false,
                styles: MapStyles,
                gestureHandling: "greedy", // allow single touch drag for map
            }
        );
    }

    // User Location methods

    requestUserCoordinatesPromise(): any {
        return new Promise(function(resolve: any, reject: any) {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    watchUserCoordinates = (): any => {
        navigator.geolocation.watchPosition(
            (position: any) => {
                if (this.userMarker) this.userMarker.setMap(null);
                const userCoordinate = this.fromCoordinatesToPoint(
                    position.coords.latitude,
                    position.coords.longitude
                );
                if (this.restrictionBounds.contains(userCoordinate)) {
                    this.drawUserMarker(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    this.setState({
                        userLocationDisabled: false,
                        userLocationInsideBounds: true,
                        userLocationShowError: false,
                    });
                } else {
                    this.setState({
                        userLocationDisabled: false,
                        userLocationInsideBounds: false,
                        userLocationShowError: false,
                    });
                }
            },
            (err: any) => {
                this.setState({
                    userLocationDisabled: true,
                    userLocationInsideBounds: false,
                    userLocationShowError: false,
                });
                console.error(err.message);
            }
        );
    };

    requestUserCoordinates() {
        if (this.userMarker) this.userMarker.setMap(null);
        this.requestUserCoordinatesPromise()
            .then((position: any) => {
                const userCoordinate = this.fromCoordinatesToPoint(
                    position.coords.latitude,
                    position.coords.longitude
                );
                if (this.restrictionBounds.contains(userCoordinate)) {
                    this.drawUserMarker(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    this.setState({
                        userLocationDisabled: false,
                        userLocationInsideBounds: true,
                        userLocationShowError: false,
                    });
                    this.watchUserCoordinates();
                } else {
                    this.setState({
                        userLocationDisabled: false,
                        userLocationInsideBounds: false,
                        userLocationShowError: true,
                    });
                }
            })
            .catch((err: any) => {
                this.setState({
                    userLocationDisabled: true,
                    userLocationInsideBounds: false,
                    userLocationShowError: true,
                });
                console.error(err.message);
            });
    }

    drawUserMarker(lat: any, long: any) {
        this.userMarker = new HtmlMapMarker({
            html: `
                <div class="user-marker"></div>
            `,
            latlng: this.fromCoordinatesToPoint(lat, long),
            map: this.googleMap,
            style: {
                zIndex: 111,
            },
            data: {},
        });
    }

    fromCoordinatesToPoint(lat: any, lon: any): any {
        return new window.google.maps.LatLng(Number(lat), Number(lon));
    }

    fitInBounds() {
        if (this.tenantMarkers && this.tenantMarkers.length) {
            this.bounds = new window.google.maps.LatLngBounds();

            for (let marker of this.tenantMarkers) {
                this.bounds.extend(marker.getPosition());
            }

            this.googleMap.fitBounds(this.bounds);

            // if (this.googleMap.getZoom()) {
            //     const zoomLevel =
            //         this.googleMap.getZoom() > this.MAX_FITBOUNDS_ZOOM
            //             ? this.MAX_FITBOUNDS_ZOOM
            //             : this.googleMap.getZoom() - 1;
            //     this.googleMap.setZoom(zoomLevel);
            // }
        } else {
            // this.googleMap.setZoom(this.DEFAULT_ZOOM);
            // this.googleMap.setCenter(
            //     this.fromCoordinatesToPoint(this.DEFAULT_LAT, this.DEFAULT_LON)
            // );
        }
    }

    zoomToTenant(lat: any, long: any, zoomLevelFromMax: number) {
        this.googleMap.setZoom(this.MAX_FITBOUNDS_ZOOM - zoomLevelFromMax);
        this.googleMap.setCenter(this.fromCoordinatesToPoint(lat, long));
    }

    toggleMarketPolygon() {
        switch (this.state.activeMarket) {
            case 1:
                this.marketPolygonFriday.setMap(this.googleMap);
                this.marketPolygonWeekend.setMap(null);
                break;
            case 2:
                this.marketPolygonFriday.setMap(null);
                this.marketPolygonWeekend.setMap(this.googleMap);
                break;
            default:
                this.marketPolygonFriday.setMap(null);
                this.marketPolygonWeekend.setMap(null);
                break;
        }
    }

    setActiveTenantMarker(firstLoad?: boolean) {
        let activeTenant;

        // disable/enable market polygon and set market marker as active marker
        if (this.marketMarker) {
            this.marketMarker.setInactive();
        }
        if (this.state.activeTenantGuid === "market") {
            activeTenant = this.marketMarker;
            this.setState({
                activeMarket: Math.abs(this.state.activeMarket),
            });
        } else {
            this.setState({
                activeMarket: Math.abs(this.state.activeMarket) * -1,
            });
        }

        // set other tenant markers to inactive
        this.tenantMarkers.forEach((tenantMarker: any) => {
            if (this.state.activeTenantGuid === tenantMarker.data.guid) {
                activeTenant = tenantMarker;
            } else {
                tenantMarker.setInactive();
            }
        });

        if (activeTenant) {
            // center map on marker
            this.zoomToTenant(
                activeTenant.data.lat,
                activeTenant.data.long,
                activeTenant.data.title === "The Rocks Markets" ? 2 : 1
            );
            // set active tenant marker state
            // delay when called on first load to allow for marker construction before calling active state
            setTimeout(
                () => {
                    if (activeTenant) {
                        activeTenant.setActive();
                    }
                },
                firstLoad ? 1000 : 500
            );
        }
    }

    setActiveAmenityMarker(clickedMarker?: any) {
        this.amenityMarkers.forEach((amenityMarker: any) => {
            amenityMarker.setInactive();
        });

        if (clickedMarker) {
            clickedMarker.setActive();
        }
    }

    setTenantTypes(tenants: any) {
        if (tenants) {
            // create unique array of tenant type objects from the overall array of tenants
            let tenantTypes = _.map(_.uniqBy(tenants, "type.id"), function(
                item: any
            ): any {
                if (
                    item.type.id === "things-to-do" ||
                    item.type.id === "stay"
                ) {
                    return null;
                }
                return {
                    id: item.type.id,
                    name: item.type.name,
                };
            });

            // prepend an "see, do & stay" type to the beggining of the tenant types array
            tenantTypes.unshift({
                name: "See, Do & Stay",
                id: "things-to-do_stay",
            });

            // prepend an "offers" type to the beggining of the tenant types array
            tenantTypes.unshift({ name: "Offers", id: "offers" });

            // prepend an "all" type to the beggining of the tenant types array
            tenantTypes.unshift({ name: "All", id: "all" });
            tenantTypes = _.filter(
                tenantTypes,
                (el: any): any => !_.isNull(el)
            );
            this.setState({
                tenantTypes: tenantTypes,
            });
        }
    }

    setInitialFilters() {
        let parsedQueryParams = queryString.parse(document.location.search, {
            decode: true,
        });
        const appliedFilters = {};

        Object.keys(parsedQueryParams).forEach((key: string) => {
            const splitArrayParam = parsedQueryParams[key].split("|");
            if (key === "category") {
                appliedFilters["categories"] = splitArrayParam;
            } else if (this.allowedFilters.indexOf(key) !== -1) {
                appliedFilters[key] = splitArrayParam;
            } else if (key === "places_to_stay") {
                appliedFilters["features"] = splitArrayParam;
            } else if (key === "things_to_do") {
                appliedFilters["activities"] = splitArrayParam;
            }
        });
        this.setState({
            appliedFilters: appliedFilters,
        });
    }

    getActiveTenantTypeFilters(): any {
        // const activeTenantFilters = _.get(
        //     this,
        //     `markerApiJson.filters[${this.state.activeTenantType}]`
        // );
        let activeTenantFilters = {
            categories: [],
            filters: [],
            name: "",
            id: "",
        };
        let hasFilters = false;

        for (const filterName in this.markerApiJson.filters) {
            const filterObject = this.markerApiJson.filters[filterName];
            if (
                this.state.activeTenantType.indexOf(filterName) >= 0 &&
                filterObject
            ) {
                activeTenantFilters.categories.push(...filterObject.categories);
                activeTenantFilters.filters.push(...filterObject.filters);
                activeTenantFilters.filters = _.uniqBy(
                    activeTenantFilters.filters,
                    "Id"
                );
                hasFilters = true;
            }
        }

        // // add tenant type friendly name and id to filters object
        if (activeTenantFilters && this.state.tenantTypes) {
            const activeTenantType = _.find(this.state.tenantTypes, {
                id: this.state.activeTenantType,
            });

            if (activeTenantType && hasFilters) {
                activeTenantFilters.name = activeTenantType.name;
                activeTenantFilters.id = activeTenantType.id;
            }
        }
        return hasFilters ? activeTenantFilters : false;
    }

    filterTenants(dontFitBounds?: boolean) {
        if (this.markerApiJson.tenants) {
            const filteredTenants = _.filter(
                this.markerApiJson.tenants,
                (tenant: any, idx: number): any => {
                    // tenant has offers filtering
                    if (
                        this.state.activeTenantType === "offers" &&
                        !tenant.hasEventsOrOffers
                    ) {
                        return false;
                    }

                    // tenant type selection matching
                    if (
                        this.state.activeTenantType !== "all" &&
                        this.state.activeTenantType !== "offers" &&
                        this.state.activeTenantType.indexOf(tenant.type.id) ===
                            -1
                    ) {
                        return false;
                    }

                    // tenant string search matching
                    if (
                        !tenant.title
                            .toLowerCase()
                            .includes(
                                this.state.tenantStringSearch.toLowerCase()
                            )
                    ) {
                        return false;
                    }

                    // filter matching
                    const filterKeyMatches = {};
                    const appliedFilterKeys = Object.keys(
                        this.state.appliedFilters
                    );

                    appliedFilterKeys.forEach((appliedFilterKey: any) => {
                        if (
                            this.state.appliedFilters[appliedFilterKey].length
                        ) {
                            filterKeyMatches[appliedFilterKey] = false;
                            const appliedFilterList = this.state.appliedFilters[
                                appliedFilterKey
                            ];
                            if (appliedFilterList.length) {
                                let tenantFilters =
                                    tenant.filterMatches[appliedFilterKey];
                                appliedFilterList.forEach(
                                    (appliedFilterValue: any): any => {
                                        if (
                                            tenantFilters.includes(
                                                appliedFilterValue
                                            )
                                        ) {
                                            filterKeyMatches[
                                                appliedFilterKey
                                            ] = true;
                                        }
                                    }
                                );
                            }
                        }
                    });

                    if (appliedFilterKeys.length === 0) {
                        //if no filter applied -> show result
                        return true;
                    }
                    if (this.state.activeTenantType === "things-to-do_stay") {
                        const hasFeatures = _.has(filterKeyMatches, "features");
                        const hasCategories = _.has(
                            filterKeyMatches,
                            "categories"
                        );
                        const hasActivities = _.has(
                            filterKeyMatches,
                            "activities"
                        );

                        const hasPrecinct = _.has(filterKeyMatches, "precinct");

                        // If none of the filters are selected, return true.
                        if (
                            !hasFeatures &&
                            !hasCategories &&
                            !hasActivities &&
                            !hasPrecinct
                        ) {
                            return true;
                        }

                        if (hasPrecinct && !filterKeyMatches.precinct) {
                            return false;
                        }

                        if (tenant.type.id === "things-to-do") {
                            // If only features are selected, return false.
                            if (
                                hasFeatures &&
                                !hasCategories &&
                                !hasActivities
                            ) {
                                return false;
                            }
                            // If categories are selected (with no activities), return filterKeyMatches.categories.
                            if (hasCategories && !hasActivities) {
                                return filterKeyMatches.categories;
                            }
                            // If activities are selected (with no categories), return filterKeyMatches.activities.
                            if (!hasCategories && hasActivities) {
                                return filterKeyMatches.activities;
                            }
                            // If both categories and activities are selected, return the logical AND of both.
                            if (hasCategories && hasActivities) {
                                return (
                                    filterKeyMatches.categories &&
                                    filterKeyMatches.activities
                                );
                            }
                            return true;
                        } else if (tenant.type.id === "stay") {
                            // If features are not selected, but either categories or activities are, return false.
                            if (
                                !hasFeatures &&
                                (hasCategories || hasActivities)
                            ) {
                                return false;
                            }
                            // If features are selected, return filterKeyMatches.features.
                            if (hasFeatures) {
                                return filterKeyMatches.features;
                            }
                            return true;
                        }
                    } else {
                        //if either of the filter doesn't match -> hide result
                        return !_.includes(filterKeyMatches, false);
                    }
                }
            );
            this.setState(
                {
                    filteredTenants: filteredTenants,
                    showMarketListItem:
                        this.state.activeTenantType === "all" &&
                        (this.state.tenantStringSearch === "" ||
                            "The Rocks Markets"
                                .toLowerCase()
                                .includes(
                                    this.state.tenantStringSearch.toLowerCase()
                                )),
                },
                () => {
                    // replot tenant markers after they've been filtered
                    this.plotTenantMarkers(dontFitBounds);
                }
            );
        }
    }

    createTenantMarker(tenant: any): typeof HtmlMapMarker {
        // create marker class
        const tenantHtmlMarker = new HtmlMapMarker({
            html: `
                <div class="tenant-marker icon icon-${
                    tenant.type.id
                } tenant-marker--${tenant.guid}">
                    ${mapPinSvgMarkup}

                    ${
                        tenant.hasEventsOrOffers
                            ? `<div class="tenant-marker__offer icon icon-offer"></div>`
                            : ""
                    }

                    <div class="tenant-marker__label">
                        <div class="tenant-marker__label__type">
                            ${tenant.type.name}
                        </div>
                        <div class="tenant-marker__label__title">
                            ${tenant.title}
                        </div>
                    </div>
                </div>
            `,
            data: tenant,
            latlng: this.fromCoordinatesToPoint(tenant.lat, tenant.long),
            map: this.googleMap,
            style: {
                zIndex: "auto",
            },
        });

        // marker event bindings

        // marker click binding
        window.google.maps.event.addListener(
            tenantHtmlMarker,
            "click",
            (dataClicked: any) => {
                // marker click event
                // redirect to tenant detail panel (hashrouter)
                window.location.hash = `map/${this.state.activeTenantType}/${tenantHtmlMarker.data.guid}`;
            }
        );

        return tenantHtmlMarker;
    }

    plotTenantMarkers(dontFitBounds?: boolean) {
        if (window.google && this.googleMap) {
            this.tenantMarkers = [];

            // clear existing markers
            if (this.tenantMarkerClusterer) {
                this.tenantMarkerClusterer.clearMarkers();
                this.setState({
                    activeMarket: Math.abs(this.state.activeMarket) * -1,
                });
            }

            // create new marker for each tenant
            this.state.filteredTenants.forEach((tenant: any) => {
                this.tenantMarkers.push(this.createTenantMarker(tenant));
            });

            // create markets marker
            if (this.marketMarker) {
                if (this.state.activeTenantType === "all") {
                    this.marketMarker.setMap(this.googleMap);
                } else {
                    this.marketMarker.setMap(null);
                }
            }

            const clusterHtml = `
                ${mapPinSvgMarkup}
                <div class="tenant-cluster-marker__counter"></div>
                <div class="tenant-cluster-marker__label"></div>
            `;

            // setup new tenant marker clustering
            this.tenantMarkerClusterer = new MarkerClusterer(
                this.googleMap,
                this.tenantMarkers,
                {
                    minimumClusterSize: 3,
                    maxZoom: 18,
                    styles: [
                        {
                            cssClass: "tenant-cluster-marker",
                            html: clusterHtml,
                            zIndex: 3,
                        },
                    ],
                }
            );

            // reset map bounds/zoom to new markers
            if (!dontFitBounds) {
                this.fitInBounds();
            }
        }
    }

    createMarketMarker() {
        if (window.google && this.googleMap) {
            const marketHtmlMarker = new HtmlMapMarker({
                html: `
                <div class="tenant-marker icon icon-market tenant-marker--market">
                    ${mapPinSvgMarkup}
                    <div class="tenant-marker__label">
                        <div class="tenant-marker__label__type">
                            Markets
                        </div>
                        <div class="tenant-marker__label__title">
                            The Rocks Markets
                        </div>
                    </div>
                </div>
            `,
                data: this.markerApiJson.markets,
                latlng: this.fromCoordinatesToPoint(
                    this.markerApiJson.markets.lat,
                    this.markerApiJson.markets.long
                ),
                map: this.googleMap,
                style: {
                    zIndex: 104,
                },
            });

            // marker event bindings
            window.google.maps.event.addListener(
                marketHtmlMarker,
                "click",
                (dataClicked: any) => {
                    // marker click event
                    // redirect to market detail panel (hashrouter)
                    window.location.hash = `map/all/market`;
                }
            );

            this.marketMarker = marketHtmlMarker;
        }
    }

    createBoundBox() {
        if (window.google && this.googleMap && this.showBounds) {
            new window.google.maps.Rectangle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.3,
                strokeWeight: 5,
                fillColor: "transparent",
                map: this.googleMap,
                bounds: this.restrictionBounds,
            });
        }
    }

    createMarketPolygon() {
        if (window.google && this.googleMap) {
            const SVGOverlay = CreateSVGOverlayFactory();
            const svgStringWeekend = `<?xml version="1.0" encoding="UTF-8"?>
                <svg viewBox="0 0 177 593" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M142.274894,570.236845 L26.3773435,545.789706 L28.1718685,540.434251 L30.7186758,532.800791 C57.7575568,451.541752 71.5425162,403.003896 72.4714545,384.979169 L72.496717,384.414799 C73.0443005,369.903836 76.9382668,351.737228 83.724664,336.056537 C92.1211674,316.655525 104.173541,302.322599 119.724166,294.936053 L126.014549,291.940023 L133.107059,288.539472 C152.394559,279.254304 164.824945,272.935851 170.663994,269.432422 C175.688702,266.417597 177.467959,259.993055 174.710068,254.822863 L48.7100675,18.6119255 C47.6971568,16.7130319 46.1494642,15.152984 44.2586845,14.1250068 L22.2586845,2.16406934 L11.7503967,21.4921807 L30.8518148,31.8772201 L150.170126,255.562062 L147.978836,256.688077 L144.542772,258.429597 C136.096368,262.680408 124.651218,268.239954 110.284916,275.063947 C89.3589425,285.003785 73.8819987,303.409279 63.5344173,327.318463 C55.6276291,345.587936 51.159633,366.432579 50.5123642,383.585201 C49.9056887,399.662103 33.4156031,456.29472 1.58363921,550.477953 C-0.525942802,556.719706 3.2874371,563.403293 9.73418769,564.763155 L137.734188,591.763155 L142.274894,570.236845 Z" id="weekend" stroke="#FFFFFF" stroke-width="2" fill-opacity="0.3" fill="#00758D" fill-rule="nonzero"></path>
                        <rect id="box" fill-opacity="0" fill="#D8D8D8" x="1" y="2" width="176" height="590"></rect>
                    </g>
                </svg>`;
            const svgStringFriday = `<?xml version="1.0" encoding="UTF-8"?>
                <svg viewBox="0 0 177 593" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M142.274894,570.236845 L26.3773435,545.789706 L28.1718685,540.434251 L30.7186758,532.800791 C57.7575568,451.541752 71.5425162,403.003896 72.4714545,384.979169 L72.496717,384.414799 C73.0443005,369.903836 76.9382668,351.737228 83.724664,336.056537 C92.1211674,316.655525 104.173541,302.322599 119.724166,294.936053 L126.014549,291.940023 L133.107059,288.539472 C145.965392,282.34936 155.575883,277.184512 161.938531,273.04493 L151.149332,255 L147.978836,256.688077 L144.542772,258.429597 C136.096368,262.680408 124.651218,268.239954 110.284916,275.063947 C89.3589425,285.003785 73.8819987,303.409279 63.5344173,327.318463 C55.6276291,345.587936 51.159633,366.432579 50.5123642,383.585201 C49.9056887,399.662103 33.4156031,456.29472 1.58363921,550.477953 C-0.525942802,556.719706 3.2874371,563.403293 9.73418769,564.763155 L137.734188,591.763155 L142.274894,570.236845 Z" id="friday" stroke="#FFFFFF" stroke-width="2" fill-opacity="0.3" fill="#00758D" fill-rule="nonzero"></path>
                        <rect id="box" fill-opacity="0" fill="#D8D8D8" x="1" y="2" width="176" height="590"></rect>
                    </g>
                </svg>`;
            // Set bottom left and top right coords
            const swLatLng = [-33.859203000000015, 151.20805199999998];
            const neLatLng = [-33.856578999999975, 151.20900100000006];

            // Create bounds
            var bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(...swLatLng),
                new window.google.maps.LatLng(...neLatLng)
            );
            this.marketPolygonFriday = new SVGOverlay(
                bounds,
                svgStringFriday,
                null
            );
            this.marketPolygonWeekend = new SVGOverlay(
                bounds,
                svgStringWeekend,
                null
            );
            this.marketPolygon = this.marketPolygonWeekend;
        }
    }

    createAmenityMarker(amenity: any): typeof HtmlMapMarker {
        // create marker class
        const amenityHtmlMarker = new HtmlMapMarker({
            html: `
                <div class="amenity-marker icon icon-amenity-${
                    amenity.type.id
                }">

                    <div class="amenity-marker__label ${
                        amenity.shortDescription.length
                            ? "amenity-marker__label--extended"
                            : ""
                    }">
                        <div class="amenity-marker__label__title">
                            ${amenity.title}
                        </div>
                        ${
                            amenity.shortDescription.length
                                ? `
                        <div class="amenity-marker__label__short-description rich-text" style="min-width: ${
                            amenity.shortDescription.length > 20 ? 160 : 0
                        }px">
                            ${amenity.shortDescription}
                        </div>`
                                : ""
                        }
                        ${
                            amenity.link && amenity.link.Url.length
                                ? `<a class="amenity-marker__label__link" href="${
                                      amenity.link.Url
                                  }" target="_blank">${amenity.link.Title ||
                                      "Find out more"}</a>`
                                : ""
                        }
                        
                    </div>
                </div>
            `,
            data: amenity,
            latlng: this.fromCoordinatesToPoint(amenity.lat, amenity.long),
            map: this.googleMap,
            style: {
                zIndex: "auto",
            },
        });

        // marker event bindings
        window.google.maps.event.addListener(
            amenityHtmlMarker,
            "click",
            (dataClicked: any) => {
                // this.setState({
                //    activeMarket: Math.abs(this.state.activeMarket) * -1,
                //});
                this.setActiveAmenityMarker(amenityHtmlMarker);
            }
        );

        return amenityHtmlMarker;
    }

    plotAmenityMarkers() {
        if (window.google && this.googleMap) {
            this.markerApiJson.amenities.forEach((amenity: any) => {
                if (amenity.type) {
                    this.amenityMarkers.push(this.createAmenityMarker(amenity));
                }
            });
        }
    }

    clearMarkers(markerArray: any): any {
        markerArray.forEach((marker: any) => {
            marker.setMap(null);
        });
    }

    //
    // Render Methods
    //

    renderTenantTypeSelection(): any {
        return this.state.tenantTypes.map((tenantType: any): any => {
            if (tenantType.id && tenantType.name) {
                return (
                    <Link
                        key={tenantType.id}
                        className={`precinct-map__tenant__selection__btn ${
                            tenantType.id === this.state.activeTenantType
                                ? "is-active"
                                : ""
                        }`}
                        to={`/map/${tenantType.id}`}
                    >
                        {tenantType.name}
                    </Link>
                );
            } else {
                return null;
            }
        });
    }

    renderTenants(): any {
        if (
            this.state.filteredTenants.length ||
            (!this.state.filteredTenants.length &&
                this.state.showMarketListItem)
        ) {
            return (
                <div>
                    {window.theme === "TheRocks" &&
                        this.state.showMarketListItem &&
                        this.markerApiJson.markets && (
                            <Link
                                className={`precinct-map__tenants__btn`}
                                to={`/map/all/market`}
                            >
                                <div
                                    className="precinct-map__tenants__btn__image"
                                    style={{
                                        backgroundImage: `url("${this.markerApiJson.markets.image}?width=160")`,
                                    }}
                                ></div>
                                <div className="precinct-map__tenants__btn__content">
                                    <div className="precinct-map__tenants__btn__content__type">
                                        Markets
                                    </div>
                                    <div className="precinct-map__tenants__btn__content__title">
                                        The Rocks Markets
                                    </div>
                                </div>
                                <div className="precinct-map__tenants__btn__icon">
                                    <i
                                        aria-hidden="true"
                                        className={`tenant-icon icon icon-market`}
                                    />
                                </div>
                            </Link>
                        )}
                    {this.state.filteredTenants.length >= 1 &&
                        this.state.filteredTenants.map(
                            (tenant: any, index: number): any => {
                                if (tenant.type.id && tenant.type.name) {
                                    return (
                                        <LazyLoad
                                            key={index}
                                            height={84}
                                            overflow={true}
                                            offset={200}
                                            once
                                        >
                                            <Link
                                                className={`precinct-map__tenants__btn`}
                                                to={`/map/${this.state.activeTenantType}/${tenant.guid}`}
                                                onClick={() => {
                                                    this.setState({
                                                        activeMarket:
                                                            Math.abs(
                                                                this.state
                                                                    .activeMarket
                                                            ) * -1,
                                                    });
                                                    this.zoomToTenant(
                                                        tenant.lat,
                                                        tenant.long,
                                                        tenant.title ===
                                                            "The Rocks Markets"
                                                            ? 2
                                                            : 1
                                                    );
                                                }}
                                            >
                                                <div
                                                    className="precinct-map__tenants__btn__image"
                                                    style={{
                                                        backgroundImage: `url("${tenant.image}?width=160")`,
                                                    }}
                                                ></div>
                                                <div className="precinct-map__tenants__btn__content">
                                                    <div className="precinct-map__tenants__btn__content__type">
                                                        {tenant.type.name}
                                                    </div>
                                                    <div className="precinct-map__tenants__btn__content__title">
                                                        {tenant.title}
                                                    </div>
                                                </div>
                                                <div className="precinct-map__tenants__btn__icon">
                                                    <i
                                                        aria-hidden="true"
                                                        className={`tenant-icon icon icon-${tenant.type.id}`}
                                                    />
                                                </div>
                                            </Link>
                                        </LazyLoad>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                </div>
            );
        } else {
            return (
                <div className="precinct-map__no-results">
                    No matching tenants found...
                </div>
            );
        }
    }

    render(): any {
        const mobileMapDragClass = this.state.mobileMapDrag
            ? `is-${this.state.mobileMapDrag}`
            : "";
        const styles = `
        .tenant-marker.tenant-marker--${this.state.activeTenantGuid} {
            transform: scale(1.50);
        }
        .tenant-marker.tenant-marker--${this.state.activeTenantGuid} .tenant-marker__label {
            transform: translate3d(-50%, -100%, 0) scale(0.666666);
            -webkit-transform: translate3d(-50%, -100%, 0) scale(0.666666);
            }
        .tenant-marker:not(.tenant-marker--${this.state.activeTenantGuid}) .map-pin__inner {
            fill: #757575 !important;
        }
        .tenant-marker.tenant-marker--${this.state.activeTenantGuid}:before {
            //transform: scale(1.40);
        }
        .tenant-marker.tenant-marker--${this.state.activeTenantGuid} .map-pin {
            //transform: scale(1.80);
        }
        `;

        const activeTenantTypeFilters = this.getActiveTenantTypeFilters();
        // console.log("activeTenantTypeFilters", activeTenantTypeFilters);

        return (
            <>
                {this.state.activeTenantGuid && (
                    <style
                        dangerouslySetInnerHTML={{
                            __html: styles,
                        }}
                    ></style>
                )}

                {/* tenant type filter modal */}
                <Modal
                    className="precinct-map__modal"
                    active={this.state.showTypeFilters}
                >
                    {/* top level filters */}
                    <PrecinctMapFilters
                        active={this.state.showTypeFilters}
                        title={`Filter ${activeTenantTypeFilters.name}`}
                        onClose={this.handleFiltersCloseClick.bind(this)}
                        resetHandler={this.handleFilterResetClick.bind(this)}
                    >
                        {activeTenantTypeFilters &&
                        activeTenantTypeFilters.categories &&
                        activeTenantTypeFilters.categories.length ? (
                            <ReactForm>
                                {activeTenantTypeFilters.categories.map(
                                    (category: any, index: number): any => (
                                        <ReactFormField
                                            className="precinct-map__filter-pane-option"
                                            type="checkbox"
                                            id={category}
                                            key={index}
                                            name={category}
                                            label={category}
                                            checked={
                                                this.state.appliedFilters
                                                    .categories &&
                                                this.state.appliedFilters.categories.includes(
                                                    category
                                                )
                                            }
                                            onChange={(): any =>
                                                this.handleFilterChange(
                                                    "categories",
                                                    category,
                                                    "checkbox"
                                                )
                                            }
                                        />
                                    )
                                )}
                            </ReactForm>
                        ) : null}

                        {/* sub filters pane buttons */}
                        {activeTenantTypeFilters
                            ? activeTenantTypeFilters.filters.map(
                                  (subFilter: any, index: number): any => (
                                      <button
                                          key={index}
                                          type="button"
                                          className="precinct-map__filter-pane-option precinct-map__filter-pane-option--btn"
                                          onClick={(): any =>
                                              this.handleSubFilterClick(
                                                  subFilter
                                              )
                                          }
                                      >
                                          <span className="precinct-map__filter-pane-option__label">
                                              {subFilter.Id}
                                          </span>
                                          {this.state.appliedFilters[
                                              subFilter.Id
                                          ] ? (
                                              <span className="precinct-map__filter-pane-option__active-filters">
                                                  {this.state.appliedFilters[
                                                      subFilter.Id
                                                  ].length > 1
                                                      ? `${this.state.appliedFilters[subFilter.Id].length} Selected`
                                                      : this.state
                                                            .appliedFilters[
                                                            subFilter.Id
                                                        ][0]}
                                              </span>
                                          ) : null}
                                          <i className="precinct-map__filter-pane-option__icon icon icon-chevron-right" />
                                      </button>
                                  )
                              )
                            : null}
                    </PrecinctMapFilters>

                    {/* second level filters */}
                    <PrecinctMapFilters
                        active={this.state.activeTypeSubFilter ? true : false}
                        title={
                            this.state.activeTypeSubFilter
                                ? this.state.activeTypeSubFilter.Id
                                : ""
                        }
                        onClose={this.handleSubFiltersCloseClick.bind(this)}
                        resetHandler={this.handleFilterResetClick.bind(this)}
                    >
                        {/* sub filter options */}
                        {/*TODO - isRadio radio options*/}
                        <ReactForm>
                            {this.state.activeTypeSubFilter &&
                            this.state.activeTypeSubFilter.Id
                                ? this.state.activeTypeSubFilter.Options.map(
                                      (subFilter: any, index: number): any => {
                                          const activeSubFilterId = this.state
                                              .activeTypeSubFilter
                                              ? this.state.activeTypeSubFilter
                                                    .Id
                                              : "";

                                          const isChecked =
                                              this.state.appliedFilters[
                                                  activeSubFilterId
                                              ] &&
                                              this.state.appliedFilters[
                                                  activeSubFilterId
                                              ].includes(subFilter);

                                          return (
                                              <ReactFormField
                                                  className="precinct-map__filter-pane-option"
                                                  type="checkbox"
                                                  id={subFilter}
                                                  key={index}
                                                  name={subFilter}
                                                  label={subFilter}
                                                  checked={isChecked}
                                                  onChange={(): any =>
                                                      this.handleFilterChange(
                                                          this.state
                                                              .activeTypeSubFilter
                                                              ? this.state
                                                                    .activeTypeSubFilter
                                                                    .Id
                                                              : "",
                                                          subFilter,
                                                          "checkbox"
                                                      )
                                                  }
                                              />
                                          );
                                      }
                                  )
                                : null}
                        </ReactForm>
                    </PrecinctMapFilters>
                </Modal>

                <div className={`precinct-map__inner ${mobileMapDragClass}`}>
                    {/* close map button */}
                    <button
                        type="button"
                        aria-label="Close Map"
                        className="precinct-map__close icon icon-close"
                        onClick={this.handleMapClose}
                    ></button>

                    {/* map area */}
                    <div className="precinct-map__map">
                        <Loader
                            active={this.state.loading}
                            absolute={true}
                            solid={true}
                        />

                        {/* google map */}
                        <div
                            ref={this.mapElementRef}
                            className="precinct-map__map__google"
                        ></div>

                        {/* user location error */}
                        {this.state.userLocationShowError && (
                            <div className="precinct-map__error-backdrop">
                                <div className="precinct-map__error-modal">
                                    <div className="precinct-map__error-title">
                                        Sorry!
                                    </div>
                                    {this.state.userLocationDisabled && (
                                        <div className="precinct-map__error-description">
                                            Please turn on location services in
                                            order to view your location on the
                                            map
                                        </div>
                                    )}
                                    {!this.state.userLocationDisabled &&
                                        !this.state
                                            .userLocationInsideBounds && (
                                            <div className="precinct-map__error-description">
                                                You must be in{" "}
                                                {window.theme ===
                                                "DarlingHarbour"
                                                    ? "Darling Harbour"
                                                    : "The Rocks"}{" "}
                                                to see your location
                                            </div>
                                        )}
                                    <i
                                        onClick={() => {
                                            this.setState({
                                                userLocationShowError: false,
                                            });
                                        }}
                                        className="icon icon-close"
                                        aria-label="Close Alert Dialog"
                                    ></i>
                                </div>
                            </div>
                        )}

                        {/* amenities toggle */}
                        {this.state.mobileMapDrag !== "expanded" &&
                        this.googleMap ? (
                            <button
                                className={`precinct-map__amenities-toggle ${
                                    !this.state.showAmenities ? "is-active" : ""
                                }`}
                                type="button"
                                onClick={this.handleAmenitiesToggle.bind(this)}
                            >
                                Amenities
                                <i aria-hidden="true" className="icon" />
                            </button>
                        ) : null}

                        {/* user location toggle */}
                        {this.state.mobileMapDrag !== "expanded" &&
                        this.googleMap ? (
                            <button
                                className={`precinct-map__user-location-toggle ${
                                    this.state.userLocationInsideBounds
                                        ? "is-active"
                                        : ""
                                }`}
                                type="button"
                                onClick={this.requestUserCoordinates.bind(this)}
                            >
                                <i aria-hidden="true" className="icon" />
                            </button>
                        ) : null}
                    </div>

                    {/* sidebar/draggable content container */}
                    <div
                        ref={this.contentElementRef}
                        className="precinct-map__content"
                    >
                        {/* tenant detail view */}
                        <PrecinctMapDetailCard
                            tenantApi={this.tenantDetailAPI}
                            hostUrl={this.apiHostname}
                            tenantGuid={this.state.activeTenantGuid}
                            market={this.markerApiJson.markets}
                            activeMarket={this.state.activeMarket}
                            setActiveMarket={(newMarket: number) => {
                                this.setState({
                                    activeMarket: Math.abs(newMarket),
                                });
                            }}
                            setDragBarFn={this.setDragBar.bind(this)}
                        />
                        <CSSTransition
                            in={this.state.activeTenantGuid ? true : false}
                            classNames="fade"
                            timeout={1000}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Link
                                aria-label="Close Tenant Detail"
                                className="map-detail-card__close cta cta--inline cta--small cta--black"
                                to={`/map/${this.state.activeTenantType}`}
                                onClick={() => {
                                    setTimeout(() => {
                                        this.setDragBar("");
                                    }, 500);
                                }}
                            >
                                <i className="icon icon-arrow-left"></i>Back
                            </Link>
                        </CSSTransition>

                        {/* mobile drag bar ui */}
                        <div
                            className="precinct-map__mobile-dragbar"
                            onClick={this.handleDragBarClick.bind(this)}
                            onTouchStart={this.handleDragBarTouchStart.bind(
                                this
                            )}
                            onTouchMove={this.handleDragBarTouchMove.bind(this)}
                            onTouchEnd={this.handleDragBarTouchEnd.bind(this)}
                        ></div>
                        {/* desktop sidebar header */}
                        {!this.state.activeTenantGuid && (
                            <div className="precinct-map__content__nav">
                                {/* desktop title/logos header */}
                                <div className="precinct-map__header">
                                    {window.theme === "TheRocks" ? (
                                        <a
                                            href="/"
                                            className="precinct-map__header__title"
                                        >
                                            The <br />
                                            Rocks
                                        </a>
                                    ) : null}

                                    <div
                                        className="precinct-map__header__logos"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.logoLockup,
                                        }}
                                    ></div>
                                </div>

                                {/* search input */}
                                <div className="precinct-map__search">
                                    <div className="precinct-map__search__inner">
                                        <label
                                            htmlFor="map-search"
                                            className="icon icon-search"
                                        />
                                        <input
                                            id="map-search"
                                            type="search"
                                            placeholder="Search by name..."
                                            onChange={this.handleSearchChange.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* tenant type selector */}
                                <div className="precinct-map__tenant__selection">
                                    {this.renderTenantTypeSelection()}
                                </div>

                                {/* Tenant Type Filter Button */}
                                {this.getActiveTenantTypeFilters() ? (
                                    <button
                                        type="button"
                                        className="precinct-map__filter-btn"
                                        onClick={this.handleFiltersOpenClick.bind(
                                            this
                                        )}
                                    >
                                        <i
                                            aria-hidden="true"
                                            className="icon icon-filter-vertical"
                                        />
                                        Filter{" "}
                                        <span className="precinct-map__filter-count">
                                            {this.state.filteredTenants.length}
                                        </span>
                                    </button>
                                ) : null}
                            </div>
                        )}
                        {/* desktop sidebar list */}
                        {!this.state.activeTenantGuid && (
                            <div
                                className="precinct-map__content__body"
                                ref={this.resultsBodyElementRef}
                            >
                                <div
                                    className="precinct-map__tenants"
                                    ref={this.tenantListElementRef}
                                >
                                    {!this.state.activeTenantGuid &&
                                        this.renderTenants()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default PrecinctMap;
